import router from './index' 

router.beforeEach((to, from, next) => {
    //标题设置
    if(to.meta.title){
        document.title=to.meta.title
    }
    
    // 切换路由页面滚动条置顶
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    window.scrollTo(0,0)

    next()
})