<template>
    <el-footer class="elfooterbox">
        <div class="ftinnerbox">
            <div>
                <!-- <p class="titletext">网页导航</p>
                <p class="dhitem dhitem1">
                    <router-link to="">企业简介</router-link>
                    <router-link to="">服务保障</router-link>
                    <router-link to="">专业维修</router-link>
                    <router-link to="">前沿资讯</router-link>
                    <router-link to="">关于我们</router-link>
                </p> -->
                <!-- <p class="dhitem">
                    <router-link to="">企业服务</router-link>
                    <router-link to="">解决方案</router-link>
                    <router-link to="">师傅介绍</router-link>
                    <router-link to="">行业资讯</router-link>
                    <router-link to="">客户案例</router-link>
                </p> -->
                <p class="titletext">联系我们</p>
                <p class="lxtext lxtext1">
                    <span class="span1">客服微信：1991 1388 5198</span>
                    <span>联系邮箱：ejiaan@163.com</span>
                </p>
                <p class="lxtext">
                    <span class="span1">联系电话：400-669-6966</span>
                    <span>联系地址：河南省郑州市金水区东风路82号</span>
                </p>
            </div>
            <div class="ftmbox">
                <div class="ftmitem">
                    <img src="@/assets/img/home/m1.png" class="imgicon" alt="">
                    <div class="text text1">关注公众号</div>
                </div>
                <div class="ftmitem ftmitem2">
                    <img src="@/assets/img/home/m2.png" class="imgicon" alt="">
                    <div class="text">亿嘉安用户版</div>
                </div>
                <div class="ftmitem">
                    <img src="@/assets/img/home/m3.png" class="imgicon" alt="">
                    <div class="text">亿嘉安师傅版</div>
                </div>
            </div>
        </div>
        <div class="bqbox">版权所有：郑州亿家安科技有限公司  
            <a href="https://beian.miit.gov.cn" target="_blank">
                <span>&nbsp;&nbsp;豫ICP备2021013173号-2</span>
            </a>
        </div>
    </el-footer>
</template>
  
<script>
export default {
name: 'FootView',
props: {
    
},
data() {
    return {

    }
},
computed: {

},
mounted() {

},
methods: {
    
}

}
</script>

<style lang="scss">
.elfooterbox {
    height: 4.6rem !important;
    width: 100%;
    padding: 0 !important;
    .ftinnerbox {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('https://csstss.com/consumer/2024-11-23/86600b43-9c86-4e96-bba6-5356b7fe34f6.png') no-repeat;
        background-size: 100% 100%;
        height: 4.2rem;
    }
    p {
        text-align: left;
    }
    .titletext {
        color: #eee;
        font-weight: 800;
        font-size: 0.24rem;
        color: #EEEEEE;
        line-height: 0.28rem;
        letter-spacing: 2px;
        margin-bottom: 0.2rem;
    }
    .titletext2 {
        margin-top: 0.76rem;
    }
    .dhitem {
        a {
            color: #ccc;
            font-weight: 500;
            font-size: 0.24rem;
            color: #CCCCCC;
            line-height: 0.28rem;
            letter-spacing: 2px;
            margin-right: 0.8rem;
        }
    }
    .dhitem1 {
        margin-bottom: 0.26rem;
    }
    .lxtext {
        span {
            font-weight: 500;
            font-size: 0.24rem;
            color: #CCCCCC;
            line-height: 0.28rem;
        }
        .span1 {
            display: inline-block;
            width: 4rem;
        }
    }
    .lxtext1 {
        margin-bottom: 0.26rem;
    }
    .ftmbox {
        display: flex;
        align-items: center;
        margin-left: 0.7rem;
        .ftmitem {
            .imgicon {
                width: 1rem;
                height: 1rem;
            }
            .text {
                margin-top: .12rem;
                font-weight: 800;
                font-size: .14rem;
                color: rgba(238,238,238,0.9);
                line-height: 0.24rem;
                letter-spacing: 2px;
            }
            .text1 {
                display: flex;
                align-items: center;
            }
            .text1::before {
                content: '';
                width: 0.24rem;
                height: 0.24rem;
                display: inline-block;
                background: url('https://csstss.com/consumer/2024-11-23/a38ffb20-1206-410b-83b8-f06f5db689c0.png') no-repeat;
                background-size: 100% 100%;
                margin-right: .01rem;
            }
        }
        .ftmitem2 {
            margin: 0 0.4rem;
        }
    }
    .bqbox {
        background: #333232;
        line-height: 0.4rem;
        font-weight: 500;
        font-size: .16rem;
        color: #fff;
        span {
            font-size: .16rem;
            color: #fff;
        }
    }
}
</style>
  