<template>
    <div class="">
      <!-- PC -->
      <div class="home hidden-xs-only">
        <Heardnav />
        <el-main class="elmainbox">
          <div class="banner">
            <img src="https://csstss.com/consumer/2024-12-11/6b6ebd1c-6617-4034-bede-7f80eba3625b.png" alt="">
          </div>
          <!-- 模块1 -->
          <div class="fivemodel">
              <div class="finneritem" v-for="(item,index) in dataList" :key="index">
                <div class="imgbox">
                  <img :src="item.icon" alt="" srcset="">
                </div>
                <div class="text1">{{ item.name }}</div>
                <div class="text2">{{ item.text }}</div>
              </div>
          </div>
          <!-- 关于我们 模块2 -->
          <div class="aboutbox">
            <div class="leftbox">
              <div class="text1">关于我们</div>
              <div class="text2">服务全国654个城市，3000个区县，力求每一点</div>
              <div class="text3">致力于打造有价值的技能共享平台，为所有人连接分享每一份技能。自成立以来，一直以“打造一个一站式多元化技能共享平台，用共享经济的理念让每个有专业技能的人各尽所能”为目标，通过品牌满足TO B、TO C用户家居服务方面的需求，为安防企业、电台广播、防盗报警等B端用户提供完整一站式售后解决方案提升服务能力，实现自我价值。</div>
              <div class="imgbox" @click="gotoPages('/Aboutus')">
                <p class="morep4">查看更多
                  <span class="rsjitem"></span>
                </p>
              </div>
            </div>
            <div class="rightbox">
              <img src="@/assets/img/home/a2.png" alt="">
            </div>
          </div>
          <!-- 模块3 -->
          <div class="modelthree pr">
            <div class="mtinner pa">
              <div class="text1">为您定制的多平台服务体系</div>
              <div class="text2">用户端、师傅端、网页端，只为满足您个性化使用习惯</div>
              <div class="text3">用户端、师傅端和网页端共同构成了一个完整的服务体系，旨在满足不同用户的使用习惯和需求。通过这三个端的协同工作，可以为用户提供更加便捷、高效和个性化的服务体验。</div>
            </div>
          </div>
          <!-- 多重服务保障  -->
          <div class="morebzbox">
              <div class="titlebox">
                  <div class="text1">多重服务保障</div>
                  <div class="text2">MULTIPLE SERVICE GUARANTEES</div>
              </div>
              <div class="btbox">
                  <div class="textbox pr">
                      <div class="textinner pa">
                          <div class="text1">{{bzTitle}}</div>
                          <div class="text2">{{bzText}}</div>
                      </div>
                      <img :src="bzImg" class="bzbigimg pa" alt="">
                      <div class="imgbox pa">
                          <div>
                              <img :src="item.img" v-for="(item,index) in bzImgArr" :key="index" @mouseover="mouseoverFn(item)" alt="">
                          </div>
                      </div>
                  </div>
                  <div class="rightbox">
                      <div>
                          <p v-for="(item,index) in bzImgArr" :key="index" :class="{active: item.sel}" @mouseover="mouseoverFn(item)">{{ item.title }}</p>
                      </div>
                      <div class="morep4" @click="gotoPages('/Service')">查看更多
                        <span class="rsjitem"></span>
                      </div>
                  </div>
              </div>
          </div>
          <!-- 行业最新动态 -->
          <div class="newdtbox">
              <div class="titlebox">
                  <div class="text1">行业最新动态</div>
                  <div class="text2">LATEST INDUSTRY NEWS</div>
              </div>
              <div class="newbtbox">
                  <div class="nbtleftbox pr">
                      <div>
                          <p v-for="(item,index) in newsList" :key="index" :class="{active: item.sel}" @mouseover="mouseoverFn2(item)" @click="gotoPages(item.path)">
                              <span class="span1 ellipsis--1">{{ item.text }}</span>
                              <span class="span2">{{ item.time }}</span>
                          </p>
                      </div>
                      <div class="morep4 pa" @click="gotoPages('/Information')">查看更多
                        <span class="rsjitem"></span>
                      </div>
                  </div>
                  <div class="newimgbox">
                      <img :src="newImg" alt="">
                  </div>
              </div>
          </div>
          <!-- 合作伙伴 -->
          <div class="hzhbbox pr">
            <div class="titlebox">
                <div class="text1">合作伙伴</div>
                <div class="text2">COOPERATION PARTNERS</div>
            </div>
            <div>
              <el-carousel :autoplay="true" arrow="never" height="3.8rem">
                <el-carousel-item v-for="(item,index) in hzList" :key="index">
                  <div>
                    <div class="swbox swbox1">
                      <p v-for="(v,i) in item.imgs" :key="i">
                        <img :src="v" v-if="i<3" alt="">
                      </p>
                    </div>
                    <div class="swbox swbox2">
                      <p v-for="(v,i) in item.imgs" :key="i">
                        <img :src="v" v-if="i>2" alt="">
                      </p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-main>
        <FootView />
      </div>
      <!-- M -->
      <div class="mobile_home hidden-sm-and-up">
        <MobileHeard />
        <el-main class="melmainbox">
          <!-- banner -->
          <div class="banner">
            <img src="https://csstss.com/consumer/2024-12-11/6005b84b-7410-49f0-b603-18cfa7d96e3d.png" alt="">
          </div>
          <!-- 模块1 -->
          <div class="mfivemodel">
              <div class="finneritem" v-for="(item,index) in dataList" :key="index">
                <div class="imgbox">
                  <img :src="item.icon" alt="" srcset="">
                </div>
                <div class="text1">{{ item.name2 }}</div>
              </div>
          </div>
          <!-- 关于我们 -->
          <div class="maboutus">
            <img src="@/assets/img/m/home/g1.png" class="titleimg" alt="">
            <div class="mabcontent pr" @click="gotoPages('/Aboutus')">
              <img src="@/assets/img/m/home/g2.png" alt="">
              <div class="center-self-x textbox">
                <p class="p1">服务全国654个城市，3000个区县，力求每一点</p>
                <p class="p2 ellipsis--3">致力于打造有价值的技能共享平台，为所有人连接分享每一份技能。自成立以来，一直以“打造一个一站式多元化技能共享平台，用共享经济的理念让每个有专业技能的人各尽所能”为目标，通过品牌满足TO B、TO C用户家居服务方面的需求，为安防企业、电台广播、防盗报警等B端用户提供完整一站式售后解决方案提升服务能力，实现自我价值。</p>
              </div>
            </div>
          </div>
          <!-- 定制多平台 -->
          <div class="mdmorebox">
            <img src="@/assets/img/m/home/d1.png" class="titleimg" alt="">
            <p>用户端、师傅端、网页端，只为满足您个性化使用习惯</p>
            <div class="mabcontent">
              <img src="@/assets/img/m/home/d2.png" alt="">
            </div>
          </div>
          <!-- 多重服务保障 -->
          <div class="mfwbzbox">
            <img src="@/assets/img/m/home/z1.png" class="titleimg" alt="">
            <div class="mabcontent" @click="gotoPages('/Service')">
              <swiper :options="mfwbzoptions" class="swiperbox">
                <swiper-slide class="mfwswiperitem">
                  <img src="@/assets/img/m/home/z2.png" alt="">
                </swiper-slide>
                <swiper-slide class="mfwswiperitem">
                  <img src="@/assets/img/m/home/z3.png" alt="">
                </swiper-slide>
                <swiper-slide class="mfwswiperitem">
                  <img src="@/assets/img/m/home/z4.png" alt="">
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <!-- 行业最新动态 -->
          <div class="mnewdtbox">
            <img src="@/assets/img/m/home/t1.png" class="titleimg" alt="">
            <ul class="clearfix">
              <li v-for="(item,index) in newsList" class="pr fl" :key="index" @click="gotoPages(item.path)">
                <img :src="item.img" alt="">
                <div class="span1 ellipsis--1 pa">{{ item.text }}</div>
              </li>
            </ul>
          </div>
          <!-- 合作伙伴 -->
          <div class="mhzhbbox pr">
            <div class="titlebox">
                <img src="@/assets/img/m/home/hb1.png" alt="">
            </div>
            <div>
              <el-carousel :autoplay="true" arrow="never" height="1.8rem">
                <el-carousel-item v-for="(item,index) in hzList" :key="index">
                  <div>
                    <div class="swbox swbox1">
                      <p v-for="(v,i) in item.imgs" :key="i">
                        <img :src="v" v-if="i<3" alt="">
                      </p>
                    </div>
                    <div class="swbox swbox2">
                      <p v-for="(v,i) in item.imgs" :key="i">
                        <img :src="v" v-if="i>2" alt="">
                      </p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-main>
        <MobileFoot />
      </div>
    </div>
  </template>
  
  <script>
  import Heardnav from '@/components/Heardnav.vue'
  import FootView from '@/components/FootView.vue'
  import MobileHeard from '@/components/MobileHeard.vue'
  import MobileFoot from '@/components/MobileFoot.vue'
  import { swiper, swiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';
  export default {
    name: 'HomeView',
    components: {
        Heardnav,
        FootView,
        MobileHeard,
        MobileFoot,
        swiper,
        swiperSlide
    },
    data() {
      return {
        mfwbzoptions: {
          slidesPerView : 2.5,
          centeredSlides : true,
          spaceBetween:15,
          loop: true,
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true//修改swiper的父元素时，自动初始化swiper
        },
        dataList: [
          {
            name: '智能化系统安装',
            name2: '智能化安装',
            icon: require('@/assets/img/home/g1.png'),
            text: '智能充电桩、监控、模块化机房、智能家居、智慧大屏等'
          },
          {
            name: '电子设备安装',
            name2: '电子设备',
            icon: require('@/assets/img/home/g2.png'),
            text: '停车管理、防盗报警、会议音响、楼宇门禁等'
          },
          {
            name: '房屋修缮',
            name2: '房屋修缮',
            icon: require('@/assets/img/home/g3.png'),
            text: '厨房改造、瓷砖地板、防水补漏、墙面翻新等'
          },
          {
            name: '家居维修',
            name2: '家居维修',
            icon: require('@/assets/img/home/g4.png'),
            text: '门窗、管道疏通、水电、开锁换锁等'
          },
          {
            name: '3C维修',
            name2: '3C维修',
            icon: require('@/assets/img/home/g5.png'),
            text: '手机、电脑等'
          },
        ],
        bzImgArr: [
            {
                img: require('@/assets/img/home/b1.png'),
                title: '平台监管，透明管控',
                text: '上门服务的师傅均持证上岗，具备专业资质，确保服务品质。准时作业无忧愁，交付质量有保障，客户满意放心用。',
                sel: true
            },
            {
                img: require('@/assets/img/home/b2.png'),
                title: '专业服务，交付无忧',
                text: '通过透明化管控确保服务质量，客户可实时查看流程节点，全面掌握服务进度，从预约到完成，每一步都清晰可见，让安装维修服务更加安心、高效。',
                sel: false
            },
            {
                img: require('@/assets/img/home/b3.png'),
                title: '售后担保，全程保障',
                text: '维修类售后担保全程保障是指提供全面的维修服务保障，确保客户在维修后享有持续、专业的服务支持。',
                sle: false
            }
        ],
        newsList:[
            {
                img: require('@/assets/img/home/n1.png'),
                text: '智能安防 | 2024安防行业前三季度报出炉：谁在争先，谁在叹气？',
                time: '11-08',
                sel: true,
                id: 0,
                path: '/Information/Details/1'
            },
            {
                img: require('@/assets/img/home/n2.png'),
                text: '智能安防 | 科技感拉满！金秋10月安防行业几款标杆性创新产品来袭',
                time: '11-07',
                sel: false,
                id: 1,
                path: '/Information/Details/2'
            },
            {
                img: require('@/assets/img/home/n3.png'),
                text: '智能安防 | 喜！这些安防设备入围工业领域2024版先进安全应急装备推广目录',
                time: '11-05',
                sel: false,
                id: 2,
                path: '/Information/Details/3'
            },
            {
                img: require('@/assets/img/home/n4.png'),
                text: '智能安防 | 安防芯片国产化进程如何了?',
                time: '10-18',
                sel: false,
                id: 3,
                path: '/Information/Details/4'
            }
        ],
        hzList: [
          {
            imgs: [
              require('@/assets/img/home/h1.png'),
              require('@/assets/img/home/h2.png'),
              require('@/assets/img/home/h3.png'),
              require('@/assets/img/home/h4.png'),
              require('@/assets/img/home/h5.png'),
              require('@/assets/img/home/h6.png')
            ]
          },
          {
            imgs: [
              require('@/assets/img/home/h7.png'),
              require('@/assets/img/home/h8.png'),
              require('@/assets/img/home/h9.png'),
              require('@/assets/img/home/h10.png'),
              require('@/assets/img/home/h11.png'),
              require('@/assets/img/home/h12.png')
            ]
          },
          {
            imgs: [
              require('@/assets/img/home/h13.png'),
              require('@/assets/img/home/h14.png'),
              require('@/assets/img/home/h15.png'),
              require('@/assets/img/home/h16.png'),
              require('@/assets/img/home/h17.png'),
              require('@/assets/img/home/h18.png')
            ]
          }
        ]
      }
    },
    computed: {

    },
    mounted() {

    },
    beforeCreate(){

    },
    created(){
        this.bzImg = this.bzImgArr[0].img;
        this.bzTitle = this.bzImgArr[0].title;
        this.bzText = this.bzImgArr[0].text;
        this.newImg = this.newsList[0].img;
    },
    methods: {
      gotoPages(page){
        this.$router.push({ path: page });
      },
      mouseoverFn(item){
          this.bzImg = item.img;
          this.bzTitle = item.title;
          this.bzText = item.text;
          let arr = this.bzImgArr;
          arr.forEach(function(val) {
              if(val.title == item.title) {
                  val.sel = true;
              }else {
                  val.sel = false;
              }
          })
          this.bzImgArr = arr;
          this.$forceUpdate();
      },
      mouseoverFn2(item){
          let arr = this.newsList;
          let that = this;
          arr.forEach(function(val) {
              if(val.id == item.id) {
                  val.sel = true;
                  that.newImg = val.img;
              }else {
                  val.sel = false;
              }
          })
          this.newsList = arr;
          this.$forceUpdate();
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  // PC  start
  .home {
    min-width: 1366px;
  }
  .elmainbox {
    padding: 0;
    overflow: hidden;
  }
  .banner {
    img {
      width: 100%;
    }
  }
  .fivemodel {
    display: flex;
    justify-content: center;
    margin-top: 0.7rem;
    .finneritem {
      width: 2.4rem;
      height: 2.8rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      margin-right: .5rem;
      border: 1px solid rgba(250, 113, 5, 0.20);
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 10px 0px rgba(250,113,5,0.2);
        border-radius: 10px;
        border: 1px solid #FA7105;
      }
      .imgbox {
        width: 0.82rem;
        height: 0.82rem;
      }
      .text1 {
        font-weight: 800;
        font-size: .2rem;
        color: #333333;
        line-height: .23rem;
        letter-spacing: 3px;
        margin: .29rem 0 .18rem;
      }
      .text2 {
        font-weight: 500;
        font-size: .16rem;
        color: #333333;
        letter-spacing: 2px;
        width: 1.9rem;
      }
    }
    .finneritem:last-child {
      margin-right: 0;
    }
  }
  .aboutbox {
    background: url('https://csstss.com/consumer/2024-11-21/cc7a47f6-c63c-47dc-82e9-47f07c18d13d.png') no-repeat;
    background-size: 100% 100%;
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    .leftbox {
      text-align: left;
      width: 8.35rem;
      .text1 {
        font-weight: 800;
        font-size: .48rem;
        color: #333333;
        line-height: .56rem;
        letter-spacing: 7px;
        margin-bottom: .2rem;
      }
      .text2 {
        font-weight: bold;
        font-size: .24rem;
        color: #333333;
        line-height: .28rem;
        letter-spacing: 3px;
      }
      .text3 {
        font-weight: 500;
        font-size: .2rem;
        color: #666666;
        line-height: 0.28rem;
        letter-spacing: 3px;
        margin: .4rem 0 .5rem;
      }
      .imgbox {
        cursor: pointer;
        img {
          width: 1.8rem;
          height: .6rem;
        }
      }
    }
    .rightbox {
      margin-left: .65rem;
      img {
        width: 5rem;
        height: 4.11rem;
      }
    }
  }
  .modelthree {
    background: url('https://csstss.com/consumer/2024-11-22/873202a9-935c-41a0-a7f7-6e779db56b86.png') bottom no-repeat;
    background-size: 100% 100%;
    height: 5.56rem;
    .mtinner {
      width: 6.8rem;
      left: 50%;
      text-align: left;
      .text1 {
        font-weight: 800;
        font-size: .48rem;
        color: #333333;
        line-height: .56rem;
        letter-spacing: 7px;
        white-space: nowrap;
      }
      .text2 {
        font-weight: 500;
        font-size: 0.24rem;
        color: rgba(0, 0, 0, 0.9);
        letter-spacing: 3px;
        margin: .2rem 0 .4rem;
      }
      .text3 {
        font-weight: 500;
        font-size: .2rem;
        color: #666666;
        letter-spacing: 3px;
      }
    }
  }
  .morebzbox {
    margin: 1rem 0;
    .btbox {
        display: flex;
        justify-content: center;
        margin-top: .6rem;
        .textbox {
            width: 8.19rem;
            height: 3.51rem;
            background: #F9F9F9FF;
            .textinner {
                width: 4.2rem;
                text-align: left;
                top: .4rem;
                right: .4rem;
                .text1 {
                    font-weight: 800;
                    font-size: 0.24rem;
                    color: #333333;
                    line-height: 0.28rem;
                    letter-spacing: 4px;
                    margin-bottom: 0.4rem;
                }
                .text2 {
                    font-weight: 500;
                    font-size:0.24rem;
                    color: #666666;
                    line-height: 0.3rem;
                    letter-spacing: 4px;
                }
            }
        }
        .imgbox {
            right: .25rem;
            bottom: -0.9rem;
            img {
                pointer-events: visible;
                width: 1.32rem;
                height: 1.32rem;
                margin-left: .19rem;
                cursor: pointer;
            }
        }
        .bzbigimg {
            width: 4rem;
            height: 4rem;
            top: 0.42rem;
            left: -0.69rem;
        }
        .rightbox {
            margin-left: 0.91rem;
            text-align: left;
            p {
                font-weight: 500;
                font-size: 0.24rem;
                color: #999999;
                line-height: .28rem;
                letter-spacing: 4px;
                display: flex;
                align-items: center;
                margin-bottom: 0.56rem;
                cursor: pointer;
                white-space: nowrap;
                &::before {
                    content: '';
                    display: inline-block;
                    width: .1rem;
                    height: .1rem;
                    background: #999;
                    border-radius: 50%;
                    margin-right: .2rem;
                }
                &.active {
                    font-size: .28rem;
                    color: #FA7105;
                    &::before {
                        content: '';
                        display: inline-block;
                        width: .1rem;
                        height: .1rem;
                        background: #FA7105;
                        border-radius: 50%;
                        margin-right: .2rem;
                    }
                }
            }
            img {
                margin-top: 0.41rem;
                cursor: pointer;
            }
        }
    }
  }
  .newdtbox {
    margin: 1.6rem 0 1rem;
    .newbtbox {
      display: flex;
      justify-content: center;
      margin-top: 0.6rem;
      .nbtleftbox {
        margin-right: 0.81rem;
        p{
          display: flex;
          justify-content: left;
          align-items: center;
          margin-bottom: 0.44rem;
          line-height: .33rem;
          width: 8.5rem;
          cursor: pointer;
          &::before {
              content: '';
              display: inline-block;
              width: .1rem;
              height: .1rem;
              background: #999;
              border-radius: 50%;
              margin-right: .2rem;
          }
        
          span {
            font-weight: 500;
            font-size: 0.24rem;
            color: #999999;
            letter-spacing: 4px;
          }
          .span1 {
            text-align: left;
            width: 6.06rem;
            margin-right: 0.28rem;
          }
          &.active span {
              font-size: .28rem;
              color: #FA7105;
          }
          &.active {
            &::before {
                content: '';
                display: inline-block;
                width: .1rem;
                height: .1rem;
                background: #FA7105;
                border-radius: 50%;
                margin-right: .2rem;
            }
          }
        }
        .moreimg {
          width: 1.8rem;
          height: 0.6rem;
          bottom: 0;
          right: .5rem;
          cursor: pointer;
        }
        .morep4 {
          right: 1rem;
          left: auto;
        }
      }
    }
    .newimgbox {
      width: 4.82rem;
      height: 4.1rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .titlebox {
    margin-bottom: 0.24rem;
    .text1 {
        font-weight: 800;
        font-size: 0.48rem;
        color: #333333;
        line-height: .56rem;
        letter-spacing: 7px;
        margin-bottom: 10px;
    }
    .text2 {
        font-weight: 500;
        font-size: 0.24rem;
        color: rgba(102,102,102,0.9);
        line-height: 0.28rem;
        letter-spacing: 4px;
    }
  }
  .hzhbbox {
    background: url('https://csstss.com/consumer/2024-11-23/563b70b3-2c28-4382-81f9-fe00dcad1ff5.png') no-repeat;
    background-size: 100% 100%;
    height: 5.6rem;
    .titlebox {
      .text1 {
        font-weight: 800;
        font-size: 0.48rem;
        color: #EEEEEE;
        line-height: 0.56rem;
        padding-top: 0.3rem;
      }
      .text2 {
        font-weight: 500;
        font-size: 0.24rem;
        color: rgba(238,238,238,0.9);
        line-height: 0.28rem;
        letter-spacing: 4px;
      }
    }
    .swbox {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin-right: 0.26rem;
        img {
          width: 3.3rem;
          height: 1.4rem;
        }
      }
    }
    .swbox1 {
      margin-bottom: 0.3rem;
      margin-right: 2.78rem;
    }
    .swbox2 {
      margin-left: 2.78rem;
    }
  }
  .morep4 {
    width: 1.99rem;
    line-height: .6rem;
    border-radius: 6px;
    border: 2px solid #F96F02;
    box-sizing: border-box;
    font-weight: 800;
    font-size: .24rem;
    color: #F96F02;
    letter-spacing: 2px;
    cursor: pointer;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .rsjitem {
      width: 0;
      height: 0;
      border-left: 8px solid #F96F02; /* 左边边框透明 */
      border-top: 5px solid transparent; /* 上边边框透明 */
      border-bottom: 5px solid transparent; /* 下边边框透明 */
      margin-left: 0.17rem;
    }
    &:hover {
      color: #fff;
      background: #F96F02;
      .rsjitem {
        width: 0;
        height: 0;
        border-left: 8px solid #fff; /* 左边边框透明 */
        border-top: 5px solid transparent; /* 上边边框透明 */
        border-bottom: 5px solid transparent; /* 下边边框透明 */
        margin-left: 0.17rem;
      }
    }
  }
  // PC  end
  // M start
  .mobile_home {
    .melmainbox {
      padding: 0;
      overflow: hidden;
    }
    .mfivemodel {
      display: flex;
      justify-content: center;
      margin: 30px 5px;
      .finneritem {
        width: 20%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        .imgbox {
          width: 36px;
          height: 36px;
        }
        .text1 {
          font-weight: 800;
          font-size: 12px;
          color: #333333;
          margin: 4px 0 0;
        }
      }
      .finneritem:last-child {
        margin-right: 0;
      }
    }
    .maboutus {
      margin: 0 16px;
      .titleimg {
        width: 67px;
        height: 28px;
        margin-bottom: 14px;
      }
      .mabcontent {
        .textbox {
          width: 100%;
          bottom: 20px;
          text-align: left;
          .p1 {
            font-size: 14px;
            color: #333;
            padding:0 10px;
            margin-bottom: 6.5px;
          }
          .p2 {
            padding:0 10px;
            font-size: 12px;
            line-height: 20px;
            color: #999;
          }
        }
      }
    }
    .mdmorebox {
      margin: 30px 16px 0;
      .titleimg {
        width: 210px;
        margin-bottom: 14px;
      }
      p {
        font-size: 12px;
        color: #333;
        margin-bottom: 12px;
      }
    }
    .mfwbzbox {
      margin: 30px 0 0;
      .titleimg {
        width: 210px;
        margin-bottom: 14px;
      }
      .mabcontent {
        .mfwswiperitem {
          width: 150px;
          height: 150px;
          img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
    .mnewdtbox {
      margin: 30px 0 40px;
      .titleimg {
        width: 148px;
        margin-bottom: 14px;
      }
      ul {
        margin: 0 16px;
        display: flex;
        justify-content: center;
        flex-flow: wrap;
        li {
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 10px;
          width: 48%;
          margin-right: 10px;
          img {
            width: 100%;
          }
          .span1 {
            display: block;
            line-height: 50px;
            background: rgba(255,255,255,0.8);
            bottom: 0;
            font-size: 14px;
            color: #333;
            text-align: left;
            text-indent: 1em;
            font-weight: bold;
          }
        }
        li:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    .mhzhbbox {
      background: url('https://csstss.com/consumer/2024-11-23/563b70b3-2c28-4382-81f9-fe00dcad1ff5.png') no-repeat;
      background-size: 100% 100%;
      height: 2.6rem;
      overflow: hidden;
      .titlebox {
        margin: 10px 0 14px;
        img {
          width: 144px;
        }
      }
      .swbox {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin-right: 4px;
          img {
            width: 1.2rem;
            height: 0.6rem;
          }
        }
      }
      .swbox1 {
        margin-bottom: 5px;
        margin-right: 50px;
      }
      .swbox2 {
        margin-left: 52px;
      }
    }

  }
  // M end
</style>
  