<template>
  <el-header class="elheaderbox">
    <div class="topview">
      <div class="left" @click="goHome">
        <img class="img" src="@/assets/logo.png" lazy-load="false" binderror="" bindload="" />
        <div>
          <div class="span1">亿嘉安</div>
          <div class="span2">智能化项目安装施工服务平台</div>
        </div>
      </div>
      <div class="right">
        <div v-for="(item,index) in dataList" :key="index" class="rightitem rightitem1" :class="{active: isActive(item.path)}" @click="gotoPath(item)">
          <span class="pr">{{ item.name }}</span>
        </div>
        <el-dropdown placement="bottom">
          <el-button class="brbuttonbox"> 
            <div class="rightitem rightitem2">
              <span>扫码关注</span>
            </div>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <div class="dropdownbox">
                <div class="dropdownitem">
                  <img src="@/assets/img/home/m1.png" alt="">
                  <div class="text">关注公众号</div>
                </div>
                <div class="dropdownitem">
                  <img src="@/assets/img/home/m2.png" alt="">
                  <div class="text">亿嘉安用户版</div>
                </div>
                <div class="dropdownitem">
                  <img src="@/assets/img/home/m3.png" alt="">
                  <div class="text">亿嘉安师傅版</div>
                </div>
              </div>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </el-header>
</template>

<script>
export default {
  name: 'TopView',
  props: {
    
  },
  data() {
    return {
      dataList:[
        {
          name: '首页',
          id: '0',
          sel: true,
          path: '/'
        },
        {
          name: '服务保障',
          id: '1',
          sel: false,
          path: '/Service'
        },
        {
          name: '专业维修',
          id: '2',
          sel: false,
          path: '/Maintenance'
        },
        {
          name: '安防学院',
          id: '3',
          sel: false,
          path: '/School'
        },
        {
          name: '前沿资讯',
          id: '4',
          sel: false,
          path: '/Information'
        },
        {
          name: '关于我们',
          id: '5',
          sel: false,
          path: '/Aboutus'
        }
      ]
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    goHome() {
      this.$router.push({ path: '/' });
    },
    gotoPath(item){
      this.$router.push({ path: item.path });
    }
  }

}
</script>

<style lang="scss" scoped>
.elheaderbox {
  height: 1.2rem !important; 
}
.brbuttonbox {
  padding: 0 !important;
  border: none !important;
  &:hover {
    background: none !important;
  }
}
.topview {
  box-shadow: 0 4px 36px -9px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  height: 1.2rem;
  color: #333;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  background: #fff;
  min-width: 13.66rem;
  .left {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    .img {
      width: 0.7rem;
      height: 0.7rem;
      margin-right: .11rem;
    }
    .span1 {
      font-size: 0.18rem;
      text-align: left;
      margin-bottom: .04rem;
      font-weight: 800;
    }
    .span2 {
      font-size: 0.14rem;
      white-space: nowrap;
    }
  }
  .right {
    display: inline-flex;
    flex: auto;
    justify-content: right;
    align-items: center;
    color: #333;
    span {
      font-size: 0.2rem;
    }
    .rightitem1 {
      &.active span {
        color: #F96F02;
      }
      &.active span::before {
        content: '';
        position: absolute;
        height: .04rem;
        width: 100%;
        background: #F96F02;
        bottom: -0.1rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .rightitem {
      padding: 0.24rem;
      cursor: pointer;
      margin-left: 0.48rem;
      white-space: nowrap;
      &:hover span {
        font-weight: bold;
      }
    }
    .rightitem2 span::before {
      content: '';
      display: inline-block;
      width: 0.2rem;
      height: 0.2rem;
      background: url('https://csstss.com/consumer/2024-11-21/3a2df3a8-7d54-46c6-b19a-b78538f7b33d.png') no-repeat;
      background-size: 100% 100%;
      vertical-align: middle;
      margin-right: .1rem;
    }
  }
}
.dropdownbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.36rem;
  width: 3.1rem;
  .dropdownitem {
    text-align: center;
    img {
      width: 1rem;
      height: 1rem;
    }
    .text {
      text-align: center;
      font-size: .14rem;
      color: #333333;
      line-height: 0.16rem;
      letter-spacing: 2px;
      
    }
  }
}
</style>
