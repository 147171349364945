<template>
  <el-header class="elheaderbox">
    <div class="topview">
      <div class="left" @click="goHome">
        <img class="img" src="@/assets/logo.png" lazy-load="false" binderror="" bindload="" />
        <div>
          <div class="span1">亿嘉安</div>
          <div class="span2">智能化项目安装施工服务平台</div>
        </div>
      </div>
      <div class="right">
        <el-button @click="drawer = true" type="primary" style="background: none;border:none;padding: 0;"
          v-if="!drawer">
          <img src="@/assets/img/m/heard/0.png" class="rimgicon" alt="">
        </el-button>
        <div class="rimgselbox" @click="drawer = false" v-else>
          <img src="@/assets/img/m/heard/1.png" class="rimgicon" alt="">
        </div>
      </div>
    </div>
    <el-drawer title="我是标题" direction="ttb" :visible.sync="drawer" size="auto" :with-header="false">
      <ul class="ulbox">
        <li v-for="(item, index) in dataList" :key="index" @click="gotoPath(item)">
          <div class="leftitem">
            <img :src="item.icon" alt="">
            <span>{{ item.name }}</span>
          </div>
          <div class="el-icon-arrow-right"></div>
        </li>
      </ul>
    </el-drawer>
  </el-header>
</template>

<script>
export default {
  name: 'TopView',
  props: {

  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      dataList: [
        {
          name: '首页',
          icon: require('@/assets/img/m/heard/2.png'),
          id: '0',
          path: '/'
        },
        {
          icon: require('@/assets/img/m/heard/3.png'),
          name: '服务保障',
          id: '1',
          path: '/Service'
        },
        {
          icon: require('@/assets/img/m/heard/4.png'),
          name: '专业维修',
          id: '2',
          path: '/Maintenance'
        },
        {
          icon: require('@/assets/img/m/heard/5.png'),
          name: '安防学院',
          id: '3',
          path: '/School'
        },
        {
          icon: require('@/assets/img/m/heard/6.png'),
          name: '前沿资讯',
          id: '4',
          path: '/Information'
        },
        {
          icon: require('@/assets/img/m/heard/7.png'),
          name: '关于我们',
          id: '5',
          path: '/Aboutus'
        }
      ]
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    goHome() {
      this.$router.push({ path: '/' });
    },
    gotoPath(item) {
      this.$router.push({ path: item.path });
    }
  }

}
</script>

<style lang="scss" scoped>
.elheaderbox {
  // height: 1.2rem !important;
}

.brbuttonbox {
  padding: 0 !important;
  border: none !important;

  &:hover {
    background: none !important;
  }
}

.topview {
  // box-shadow: 0 4px 36px -9px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 9999999999;
  left: 0;
  top: 0;
  right: 0;
  height: 60px;
  color: #333;
  display: flex;
  align-items: center;
  padding: 0 0.32rem;
  background: #fff;
  justify-content: space-between;

  .left {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .img {
      width: 36px;
      height: 36px;
      margin-right: .11rem;
    }

    .span1 {
      font-size: 0.2rem;
      text-align: left;
      margin-bottom: .04rem;
      font-weight: 800;
    }

    .span2 {
      font-size: 0.14rem;
      white-space: nowrap;
    }
  }

  .right {
    .rimgicon {
      width: 0.3rem;
      height: 0.22rem;
    }
  }
}

.ulbox {
  padding-top: 1rem;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.32rem;
    height: 0.65rem;
    border-bottom: 1px solid #F6F6F6;
    font-size: 16px;
    .leftitem {
      display: inline-flex;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
        margin-right: 0.04rem;
      }
    }
  }

  li:last-child {
    border-bottom: none;
  }
}
</style>