import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home'

Vue.use(VueRouter)

// 防止重复路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{
      title:"亿嘉安",
    },
    component: HomeView
  },
  {
    path: '/Home',
    name: 'home',
    meta:{
      title:"亿嘉安",
    },
    component: HomeView
  },
  {
    path: '/Service',
    name: 'service',
    meta:{
      title:"服务保障",
    },
    component: () => import('@/views/Service')
  },
  {
    path: '/Maintenance',
    name: 'maintenance',
    meta:{
      title:"专业维修",
    },
    component: () => import('@/views/Maintenance')
  },
  {
    path: '/School',
    name: 'school',
    meta:{
      title:"安防学院",
    },
    component: () => import('@/views/School')
  },
  {
    path: '/Information',
    name: '',
    meta:{
      title:"前沿资讯",
    },
    component: () => import('@/views/Information'),
    children: [
        {
            path: '',
            name: 'information',
            meta:{
              title:"前沿资讯",
            },
            component: () => import('@/views/Information/List')
        },
        {
            path: 'Details/:id',
            name: 'InformationDetails',
            meta:{
              title:"资讯详情",
            },
            component: () => import('@/views/Information/Details')
        }
    ]
  },
  {
    path: '/Aboutus',
    name: 'aboutus',
    meta:{
      title:"关于我们",
    },
    component: () => import('@/views/Aboutus')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/components/NotFound')
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
