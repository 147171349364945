<template>
    <el-footer class="melfooterbox">
        <div class="ftinnerbox">
            <div>
                <p class="titletext titletext2">联系我们</p>
                <div class="lxtext lxtext1">
                    <p>
                        <img src="@/assets/img/m/home/f1.png" alt="">
                        <span>1991 1388 5198</span>
                    </p>
                    <p class="p2">
                        <img src="@/assets/img/m/home/f2.png" alt="">
                        <span>400-669-6966</span>
                    </p>
                </div>
                <div class="lxtext">
                    <p>
                        <img src="@/assets/img/m/home/f3.png" alt="">
                        <span>ejiaan@163.com</span>
                    </p>
                    <p class="p2">
                        <img src="@/assets/img/m/home/f4.png" alt="">
                        <span>河南省郑州市金水区东风路82号</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="bqbox">版权所有：郑州亿家安科技有限公司  
            <a href="https://beian.miit.gov.cn" target="_blank">
                <span>&nbsp;&nbsp;豫ICP备2021013173号-2</span>
            </a>
        </div>
    </el-footer>
</template>
  
<script>
export default {
name: 'FootView',
props: {
    
},
data() {
    return {

    }
},
computed: {

},
mounted() {

},
methods: {
    
}

}
</script>

<style lang="scss">
.melfooterbox {
    height: auto !important;
    width: 100%;
    overflow-x: hidden;
    padding: 0 !important;
    .ftinnerbox {
        background: url('https://csstss.com/consumer/2024-11-23/86600b43-9c86-4e96-bba6-5356b7fe34f6.png') no-repeat;
        background-size: 100% 100%;
        height: 1.98rem;
    }
    p {
        text-align: left;
        display: flex;
        align-items: center;
    }
    .p2 {
        width: 2.9rem;
    }
    .titletext {
        color: #eee;
        font-size: 14px;
        color: #EEEEEE;
        letter-spacing: 2px;
        padding: 0.2rem 0.2rem 0.35rem;
    }
    .lxtext {
        display: flex;
        align-items: center;
        padding: 0 0.2rem;
        justify-content: space-between;
        img {
            width: 0.22rem;
            height: 0.22rem;
            margin-right: 0.04rem;
        }
        span {
            font-weight: 500;
            font-size: 12px;
            color: #CCCCCC;
            flex: auto;
        }
        .span1 {
            display: inline-block;
            width: 4rem;
        }
    }
    .lxtext1 {
        margin-bottom: 0.26rem;
    }
    .bqbox {
        background: #333232;
        line-height: 0.4rem;
        font-weight: 500;
        font-size: 10px;
        color: #fff;
        span {
            font-size: 10px;
            color: #fff;
        }
    }
}
</style>
  